<template>
<div class="hero">
  <BackSmile class="hero__backsmile"/>
  <Ball class="ball--big hero__ball" v-if="showSideBall"/>
  <div class="hero__inner">
    <div class="left-hero" :class="{ 'more-space': !$slots.default && !image }">
      <h1 class="hero__title heading">
        <template v-if="useColoredHeading">
          <span v-for="(word, i) in heading" :key="i" :class="{'text--red': word.colored }">{{ word.value }}&nbsp;</span>
        </template>
        <template v-else>
          <span class="text--red">{{ data.heading }}</span>
        </template>
      </h1>
      <p class="hero__text">{{ data.text }}</p>
      <div class="hero__buttons" v-if="data.buttons && data.buttons.length > 0">
        <ButtonLink 
          v-for="(button, i) in data.buttons" 
          :key="button.id" 
          :link="button.link" 
          :class="i === 1 ? 'btn--secondary ml-3' : ''"
        >{{ button.label }}</ButtonLink>
      </div>
    </div>
    <div class="right-hero" v-if="$slots.default || image">
      <nuxt-img v-if="image" provider="strapi" :src="`${image.hash}${image.ext}`" :style="[
        data.image_margin_top
        ? `transform: translateY(${((data.image_margin_top + (16 * 4)) / image.height) * 100}%); margin-top: -${((data.image_margin_top + (16 * 4)) / image.height) * (image.width / image.height) * 100}%; max-width: 100%;`
        : ''
      ]"/>
      <slot></slot>
    </div>
  </div>
</div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import BackSmile from '~/assets/svg/smile_icon.svg?component';
import useStrapiHelper from "~~/composables/strapi";

const props = defineProps({
  data: {
    required: true,
    type: Object as PropType<object>
  },
  showSideBall: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  useColoredHeading: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const { flattenObject } = useStrapiHelper();

const coloredWords = ['create', 'brands', 'smile'];
const heading = computed(() => props.data.heading.split(' ').map((word) => ({ colored: coloredWords.includes(word.toLowerCase()), value: word})));

const image = computed(() => props.data?.image?.data ? flattenObject(props.data.image) : null);
</script>

<style lang="scss">
.hero {
  width: 100%;
  position: relative;
  z-index: 1;
}

.hero__ball {
  position: absolute;
  top: $p-4;
  left: -8rem;

  @include breakpoint(null, $breakpoint_md) {
    display: none;
  }
}

.hero__inner {
  @include maxBody();
  padding: $p-4 1.5rem;
  position: relative;
  display: flex;
  justify-content: space-between;

  @include breakpoint(null, $breakpoint_lg) {
    flex-direction: column;
    align-items: center;
  }

  @include breakpoint($breakpoint_sm, $breakpoint_lg) {
    text-align: center;
  }
}

.right-hero {
  margin-left: 3rem;
  flex-shrink: 0;
  align-self: flex-end;

  @include breakpoint(null, $breakpoint_lg) {
    margin-left: 0;
    align-self: center;
    margin-top: 2rem;
  }
}

.left-hero {
  position: relative;
  max-width: 41rem;
  width: 100%;
  z-index: 1;
  flex-shrink: 0;

  &.more-space {
    max-width: 80%;
  }
}

.hero__title {
  display: flex;
  flex-wrap: wrap;
  color: $violet_blue;

  @include breakpoint($breakpoint_sm, $breakpoint_lg) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.hero__text {
  font-size: 1.5rem;
  line-height: 1.75;
  color: $dark_grey;
  margin: $p-3 0;
  // max-width: 526px;
  display: block;

  @include breakpoint(null, $breakpoint_sm) {
    font-size: 1.1rem;
  }

  .more-space & {
    max-width: none;
  }
}

.hero__buttons {
  display: inline-flex;

  @include breakpoint(null, $breakpoint_sm) {
    flex-direction: column;
    gap: 2rem;
    display: flex;
  }

  @include breakpoint($breakpoint_sm, $breakpoint_lg) {
    margin: 0 auto;
  }
}

.hero__backsmile {
  position: absolute;
  height: 476px;
  width: auto;
  right: -15rem;
  top: 0;
  fill: $violet_blue;
  opacity: 0.14;
  z-index: 0;
  transform: rotate(43deg);

  @include breakpoint(null, $breakpoint_lg) {
    height: 20rem; 
  }

  @include breakpoint(null, $breakpoint_md) {
    height: 12rem;
    right: -6rem;
    top: -8rem;
  }
}
</style>
